@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');


#app {
    height: 100%
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #e5e5e5;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}
.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: #643392!important;
    transform-origin: 0%;
}
.swiper {
    width: 100%;
    /* height: 24em; */
    /* background-image: url(../img/sliderbg.png); */
}

.swiper-slide {
    background-position: center;
    background-size: cover;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.bg-img{
    width: 100%;
    height: 40em;
}

.slider-container{
    position: relative;
        text-align: center;
        color: white;
}

.centered {
position: absolute;
    top: 8px;
    right: 16px;
}

.img-text-wrapper{
        /* background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(71 71 71)); */
            height: 100%;
            width: 100%;
}

.img-text-container{
    position: absolute;
        top: 8px;
        /* right: 12em; */
        color: white;
        margin-left: 50em;
        text-align: center;
        margin-top: 3em;
}

.banner-bg{
        height: 100%;
            width: 100%;
            clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
            position: relative;
            LEFT: 26em;
            background: linear-gradient(to right, rgb(16 16 16 / 44%), rgb(0 0 0));
}
.b1p2{
    font-family: 'Gambarino', serif;
    font-size: 29px;
    line-height: 3.1em;
}
.b1h2, .b1h1{
    font-size: 35px;
    font-family: 'Clash Display', sans-serif;
}
.b1h2 span, .b1h1{
    color: #FFBD59;
}
.b1p3{
    font-family: 'Gambarino', serif;
        font-size: 20px;
}
.b1h1{
    animation-delay: 0.5s;
}
.b1p2{
    animation-delay: 0.7s;
}

.b1h2{
    animation-delay: 0.9s;
}
.b1p3{
    animation-delay: 1.1s;
}
.b2p1{
    font-family: 'Gambarino', serif;
        font-size: 20px;
}
.con-p1, .con-p2, .con-p3, .form-control{
    font-family: 'Gambarino', serif;
    font-size: 20px;
    font-weight: bolder;
}
.con-h1, .con-h2{
    color: #643392;
    font-family: 'Clash Display', sans-serif;
    
}
.b2b1{
    transition: transform 0.4s!important;
    font-family: 'Gambarino', serif;
        font-size: 20px!important;
            font-weight: bold!important;
    text-transform: inherit!important;
}
.b2b1 svg{
    margin-bottom: 5px;
    font-size: 20px;
    margin-left: 5px;
}
.b2b1:hover{
    transform: scale(1.2);
}
.about-us{
    text-align: center;
}
.about-p1, .about-p2{
    
    margin-right: 3em;
}
.about-head, .choose-head, .client-head, .gallery-head, .contact-head, .about-page-head, .interior-section-head, .gallery-video-head{
    color: #643392;
    font-family: 'Clash Display', sans-serif;
}
.about-head:after, .choose-head:after, .client-head:after, .gallery-head:after, .contact-head:after, .about-page-head:after, .interior-section-head:after, .gallery-video-head:after{
    content: "";
    display: block;
    width: 50%;
    max-width: 70%;
    border-bottom: 0.1em solid #FFBD59;
    margin: 0.6em auto 0;
}
.about-p2{
    font-family: 'Gambarino', serif;
}
.interior-showcase-p{
    font-family: 'Gambarino', serif;
    font-size: 18px;
}
.choose-p{
    font-family: 'Gambarino', serif;
    font-size: 18px;

}
.choose-sub-head{
    font-family: 'Merriweather', serif;
    font-weight: bold;
}
.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #0C9;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #0c9;
    z-index: 999;
    transition: transform 0.5s;
}

.showcase-img{
    width: 50%;
    padding: 50px;
    margin: auto;
        width: 50%;
            --s: 10px;
            padding: var(--s);
            border: calc(2*var(--s)) solid #0000;
            outline: 1px solid #000;
            outline-offset: calc(-1*var(--s));
            background: conic-gradient(from 90deg at 1px 1px, #0000 25%, #000 0);
}

.my-float {
    margin-top: 12px;
    font-size: 35px;
}
.float:hover{
    transform: scale(1.2);
}
.avatar-img{
    width: 50%;
    border-radius: 50%;
}
.star-img{
    width: 50%;
}
.review-container{
    text-align: center;
}
.client-review{
    font-family: 'General Sans', sans-serif;
}
.submit-btn{
    border-color: #643392!important;
    color: #643392!important;
}
.submit-btn:hover{
    background-color: #643392!important;
    color: white!important;
    /* border-color: #643392!important; */
}
.about-parallax{
    background: linear-gradient(to right, rgb(16 16 16 / 44%), rgb(0 0 0));
    color: white;
    font-size: 1.3em;
}
.about-content{
    border: 2px solid #643392;
    padding: 20px;
    border-radius:30px;
}
.ab-p1, .ab-p2, .ab-p3{
    font-family: 'Gambarino', serif;
}
@media only screen and (max-width: 1000px) and (min-width: 764px) {
    .banner-bg{
        left: 12em;
    }
}
@media only screen and (max-width: 450px) and (min-width: 200px){
    .img-text-container{
        font-size: 0;
        top: 80px;
    }
    .swiper-button-next, .swiper-button-prev{
        display: none;
    }
    .banner-bg{
        clip-path: none;
        left: 0;
        background: linear-gradient(to right, rgb(16 16 16 / 44%), rgb(0 0 0 / 46%));
    }
    .about-p2{
        margin-right: 0;
        margin: 1em;
    }
    .parallax-h{
        height: 650px!important;
    }
}